<agdir-button (click)="open()" *ngIf="hasCameraPermission; else noCameraExist" [class.hidden]="doNotRender" color="outline" icon="photo_camera" />

<ng-container *ngIf="showCamera | async">
	<div class="camera-view">
		<webcam
			#webcam
			(imageCapture)="handleImage($event)"
			[height]="windowSize.height"
			[switchCamera]="nextWebcamObservable"
			[trigger]="triggerObservable"
			[width]="windowSize.width"></webcam>
		<div class="flex items-center justify-center fixed top-1/2 left-0 gap-5 w-full">
			<ng-container *ngIf="isIdle$ | async; else uploading">
				<button (click)="webcam.takeSnapshot()" class="text-lg md:text-2xl">
					<agdir-icon icon="photo_camera" />
				</button>
				<button (click)="changeWebCam(true)" class="text-lg md:text-2xl">
					<agdir-icon icon="camera_front" />
				</button>
				<button (click)="showCamera.next(false)" class="text-lg md:text-2xl">
					<agdir-icon icon="close" />
				</button>
			</ng-container>
		</div>
	</div>
</ng-container>

<ng-template #noCameraExist>
	<span transloco="media.noPermission"></span>
</ng-template>

<ng-template #uploading>
	<agdir-spinner></agdir-spinner>
</ng-template>
